import { io } from "socket.io-client";

const socket = io(process.env.SOCKETIO_URL, { autoConnect: false });

// Try connect to the websocket
console.log("connecting to ws as output session..");
socket.auth = { outpuSession: true, token: process.env.OUTPUT_TOKEN };
socket.connect();


export default socket;
